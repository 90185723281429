<template>
<div v-if="data.kfShow" class="bback" @click="data.kfShow=false">
</div>
<div v-if="data.kfShow" class="pop-kf-div">
	<div class="pop-close" @click="data.kfShow=false">×</div>
	<div class="pop-kf">
        <div class="pop-ewm">
            <img :src="data.ewm"/>
        </div>
        <div class="pop-kf-desc">
            <img src="@/assets/img/me/icon_kf.png"/>
            <div class="sp"></div>
            <div>长按扫码添加客服</div>
        </div>
	</div>
</div>
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../common/http.js';
import {isNull,nullStr,showMessage} from '../common/utils.js'
let data=reactive({
  kfShow:false,
  ewm:""
})

function showKf(){
    api.post("/web/pick/customer",{}).then((res)=>{
        if(res.errcode=="0"){
          data.ewm=res.ewm;
        }else{
          showMessage(res.errmsg);
        }
  })
    data.kfShow=true;
}

defineExpose({showKf})

</script>

<style scoped lang="scss">
.pop-kf-div{
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-kf{
    display: flex;
    margin:2rem;
    .pop-ewm{
        flex:1;
        
        img{
            width:100%;
        }
    }
    .pop-kf-desc{
        flex:1;
        text-align: right;
        margin-top:1rem;
        margin-left:2rem;
        img{
            width:4rem;
        }
        .sp{
            height:1px;
            margin-top:1rem;
            margin-bottom: 1rem;
            background: #ebebeb;
        }
    }
  }

}
</style>