<template>
  
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage,nullStr} from '../../common/utils.js'
import KfCard from '../../components/KfCard.vue'
import ShareCard from '../../components/ShareCard.vue'
import { areaList } from '@vant/area-data'

let route=useRoute()
let router=useRouter()

let data=reactive({
    code:route.query.code,
    state:route.query.state 
})

function searchCode(){
  localStorage.clear();
//   alert("1")
  api.post("/web/wx/check",{code:data.code,userType:"06"}).then((res)=>{
    // alert(JSON.stringify(res))
        if(res.errcode=="0"){
          if(res.login){
            var levelmap={};
            for(var i=0;i<res.levellist.length;i++){
                var item=res.levellist[i];
                levelmap[item.level_commission]=item;
            }
            localStorage.setItem("userType","06");
            localStorage.setItem("token",res.token);
            localStorage.setItem("levelmap",JSON.stringify(levelmap));
            
            localStorage.setItem("name",res.name);
            localStorage.setItem("img",res.img);
            if(res.upname){
                localStorage.setItem("upname",res.upname);
                localStorage.setItem("upimg",res.upimg);
                localStorage.setItem("upphone",res.upphone);
            }
            if(res.level){
                localStorage.setItem("levelup",res.levelup);
                localStorage.setItem("level",res.level);
                localStorage.setItem("levelname",res.levelname);
                localStorage.setItem("levelimg",res.levelimg);
            }else{
                localStorage.setItem("levelup","C");
                localStorage.setItem("level","D");
                localStorage.setItem("levelname","普通会员");
                localStorage.setItem("levelimg","");
            }
            // router.push({ path: '/home2'})
            // alert(JSON.stringify(res))
            location.href=data.state;
          }else{
            
            localStorage.setItem("opentoken",res.opentoken);
            location.href=data.state;
          }
        }else{
          showMessage(res.errmsg);
        }
  })
}

searchCode()


</script>
<style scoped lang="scss">

</style>